.root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20rem;
  margin: 0 auto;
}

.root :global(.progress-bar) {
  transition-duration: 0.1s; /* show progress changes faster */
}

/* Source: https://github.com/tobiasahlin/SpinKit/blob/master/spinkit.css#L429 */
.grid {
  --w: 2.5rem;
  display: grid;
  align-self: center;
  grid: var(--w) var(--w) var(--w) / var(--w) var(--w) var(--w);
  width: calc(var(--w) * 3);
  height: calc(var(--w) * 3);
  margin-top: -15vh; /* vertical offset from center */
  margin-bottom: 3rem;
}

.grid > div {
  background-color: var(--bs-cyan);
  animation: scaleDelay 1.3s infinite ease-in-out;
}

.grid > div:nth-child(1) {
  animation-delay: 0.2s;
}
.grid > div:nth-child(2) {
  animation-delay: 0.3s;
}
.grid > div:nth-child(3) {
  animation-delay: 0.4s;
}
.grid > div:nth-child(4) {
  animation-delay: 0.1s;
}
.grid > div:nth-child(5) {
  animation-delay: 0.2s;
}
.grid > div:nth-child(6) {
  animation-delay: 0.3s;
}
.grid > div:nth-child(7) {
  animation-delay: 0s;
}
.grid > div:nth-child(8) {
  animation-delay: 0.1s;
}
.grid > div:nth-child(9) {
  animation-delay: 0.2s;
}

@keyframes scaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}
