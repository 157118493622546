.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.inner {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  padding: 1em;
  overflow: visible auto;
  scrollbar-width: thin;
}

.pathInput {
  margin-bottom: 1rem;
  text-overflow: ellipsis;
}

.pathTooltip :global(.tooltip-inner) {
  max-width: none;
}

.filter {
  position: relative;
  z-index: 0;
}

.clearBtn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 4; /* above input when focused */
  display: flex;
  align-items: center;
  color: inherit;
}

.entityIcon {
  margin-right: 3%;
}

.list {
  font-size: 90%;
  margin-top: 0.5rem;
}

.item {
  padding: 0.375rem 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;
}

.item[data-current] {
  font-weight: bold;
}

.hintItem {
  composes: item;
  font-style: italic;
  color: #495057;
}

.status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1.5rem;
  background-color: var(--braggy-cyan-bg);
  box-shadow: 0 -1px 4px white;
  font-size: 90%;
}

.status > p {
  margin-bottom: 0;
}

.refreshBtn {
  font-size: inherit;
}

.autoload {
  display: flex;
  justify-content: space-between;
  font-size: 90%;
  padding: 0.375rem 0;
}

.sortInput svg {
  overflow: visible;
}
