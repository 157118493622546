body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --braggy-sidebar: hsl(285, 100%, 70%);

  --braggy-cyan-bg: hsl(300, 100%, 80%) /*hsl(190, 90%, 94%) /*hsl(188.2, 35%, 92%)*/;
  --braggy-cyan-bg-light: hsl(300, 100%, 90%) /*hsl(190, 90%, 98%) /*hsl(188.2, 35%, 92%)*/;

  --h5w-toolbar--bgColor: var(--braggy-cyan-bg);
  --h5w-btn-hover--bgColor: white;
  --h5w-selector-option-selected--bgColor: var(--braggy-cyan-bg);
  --h5w-domainSlider-track--width: 12rem;
  --h5w-domainSlider-dataTrack--bgColor: var(--bs-cyan);
  --h5w-domainSlider-dataTrack--shadowColor: var(--bs-gray);
  --h5w-domainSlider-thumb--bgColor: white;
  --h5w-domainSlider-thumb-auto--bgColor: white;
  --h5w-domainSlider-boundInput-editing--borderColor: var(--bs-cyan);
  --h5w-domainSlider-boundInput-focus--shadowColor: var(--bs-gray);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Put tooltip above canvas annotations */
.visx-tooltip {
  z-index: 1;
}
