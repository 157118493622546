.profileContainer {
  position: relative;
  display: flex;
  flex: 1 1 0;
  min-height: 0;
  font-size: 0.75rem;
}

.heatmapContainer {
  display: flex;
  flex: 3 1 0;
  min-height: 0;
}
