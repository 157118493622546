.root {
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-bottom: 2rem;
}

.logo {
  display: block;
  margin-top: 0.5rem;
  width: calc(5.625rem + 5vw); /* keep same height as [heading + subtitle] */
}

@media (min-width: 1200px) {
  .logo {
    width: 150px;
  }
}

.loading {
  display: flex;
  color: darkslategray;
  justify-content: center;
}
