.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  padding: 1rem 0.375rem;
  background-color: var(--braggy-sidebar);
  font-size: 1.75rem;
}

.menu > button,
.menu > a {
  display: flex;
  align-items: center;
  padding: 0.375rem;
  color: inherit;
  font-size: inherit;
}

.menu > button:hover,
.menu > a:hover {
  color: white;
}

.menu > button:focus,
.menu > a:focus {
  box-shadow: none;
}

.menu > button:focus-visible,
.menu > a:focus-visible {
  box-shadow: 0 0 0 2px white;
}

.menu > button[data-active] {
  background-color: var(--bs-dark);
  color: white;
}

@media (min-width: 200px) {
  .logo {
    width: 50px;
  }
}

.logoutBtn {
  margin-top: auto;
}
