.root {
  display: flex;
  height: 100vh;
  overflow: hidden; /* prevent overflow when scrolling up in file browser with tooltip visible */
}

.container {
  flex: 1 1 0%;
  display: flex;
  min-width: 0;
}

:global(.reflex-container.vertical) > .handle {
  display: flex;
  align-items: center;
  width: 0.625rem;
  background-color: #e3e3e3;
  border-right: none !important;
  border-left-color: #eee !important;
  color: #777;
  cursor: col-resize;
  transition: none;
}

:global(.reflex-container.vertical) > .handle:hover {
  background-color: #d3d3d3;
}

.panel {
  min-width: 0;
  background-color: rgb(250, 250, 250);
}

.content {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.toolbar {
  display: flex;
  z-index: 1;
  background-color: var(--braggy-cyan-bg);
  height: 2.5rem;
}
