.container {
  display: flex;
  align-items: center;
}

.square {
  width: 1rem;
  height: 1rem;
  border: solid 1px black;
}

.option {
  display: flex;
  align-items: center;
}

.optionLabel {
  margin-left: 0.25rem;
}
